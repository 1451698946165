import React, { Component } from 'react';
import {Accordion} from 'primereact/components/accordion/Accordion';
import {AccordionTab} from 'primereact/components/accordion/Accordion';
import { SPECIAL_NOTE_BIG,SPECIAL_NOTE_BIG_PPL,SPECIAL_NOTE_ACTIVATE } from '../constants';

export class AiReview extends Component {
        

   

    render() {
        var w = window.innerWidth
        var h = window.innerHeight
        if (w > 640) {
            w=640
        }
        if (h > 640) {
            h=640
        }
        const note1 =<div><br/><br/><br/><p/>Actual presentation might appear slightly different from preview. Our experts will ensure the proper size and placement of your message. Items engraved with personalized information cannot be returned or exchanged. Inappropriate and profane language may result in the cancellation of your order.</div>
        let note2a = <div></div>
        if (SPECIAL_NOTE_ACTIVATE){
         note2a = <div><font color='red'>{SPECIAL_NOTE_BIG}</font></div>
            if (window.currentSite === 'peoplesjewellers') 
            note2a = <div><font color='red'>{SPECIAL_NOTE_BIG_PPL}</font></div>
         }

        var note2 = <div>Order processing requires <font color='red'>15 Business days PLUS shipping time</font> based on the shipping method you choose. Signature required for items over $1,000.</div>
         if (window.currentSite === 'peoplesjewellers') 
            note2 = <div>Order processing requires <font color='red'>24 Business days PLUS shipping time</font> based on the shipping method you choose. Signature required for items over $1,000.</div>
        let noteGem =''
        let tableDesc=[]
            
           ////console.log('REVIEW STATE',this.props.properties)
            for (let key in this.props.properties.specs)
            {
              let p = this.props.properties.specs[key]
              
              tableDesc.push (<div key={key} className='ai-table-group'>{p.group}</div>)
              let i='1'
              for (let key2 in p.properties){
                let p2 = p.properties[key2]
                tableDesc.push(<div key={key +'-'+key2} className='ai-table-row'><div className={'ai-table-column1 ai-table-background'+i}>{p2.name}</div><div className={'ai-table-column2 ai-table-background'+i}>{p2.description}</div></div>)
                if (i === '1') i = '2'
                else i = '1'
              }
              
              
            }
                    
            let headercolor ='ai-review-header ai-review-' + window.currentSite
 
        if (this.props.gems)
         noteGem = <div>Most natural gemstones pictured have been subject to an enhancement process or treatment (e.g. heating, oiling, diffusion, waxing, etc.), which may not be permanent and may require special care. Please refer to the FAQ section below for more information about gemstone enhancements and special care recommendations.</div>
        return (
            <div className={"ai-review " + this.props.showReview} >
                <Accordion  onTabOpen={this.props.onOpenReview} >

                <AccordionTab header="REVIEW"   headerClassName={headercolor}  icon="pi pi-plus" >
                        <div className='ai-review-title'> {this.props.description}</div> 
                        {tableDesc}
                        <div className="ai-review-row">
                        <div className='ai-review-note'>
                        {noteGem}
                        </div>   
                        <div className='ai-review-note'></div>
                        <div className='ai-review-note'>
                        {note1}
                        </div>       
                        <div className='ai-review-note'>
                        {note2a}
                        </div>       
                        <div className='ai-review-note'>
                        {note2}
                        </div>       
                        </div>
                        <div className='ai-version'>Version {this.props.version}</div>
                    </AccordionTab> 
                    
                </Accordion>
              
            </div>
        )
    }
}
export default AiReview;
